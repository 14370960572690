import React, { Suspense } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { Provider } from "react-redux"
import { PersistGate } from 'redux-persist/integration/react'
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import RouteHandler from "./RouteHandler";
import store, { persistor } from "./redux/Store";
import { setupLogRocket } from "./services/LogRocketService";
import { setupNewRelic } from "./services/NewRelicService";
import ConsentForm from "./components/views/ConsentForm";
import Loading from "./components/common/Loading";
import ErrorFallback from "./components/common/ErrorFallback";

import "./App.css";

const CreateRoom = React.lazy(() => import("./components/views/CreateRoom"));
const JoinRoom = React.lazy(() => import("./components/views/JoinRoom"));
const HowToPlayTab = React.lazy(() => import("./components/views/HowToPlayTab"));
const About = React.lazy(() => import("./components/views/About"));
const PickGame = React.lazy(() => import("./components/views/PickGame"));
const AddFriends = React.lazy(() => import("./components/views/AddFriends"));
const MenuTab = React.lazy(() => import("./components/views/MenuTab"));
const Play = React.lazy(() => import("./components/views/Play"));
const PaymentResult = React.lazy(() => import("./components/views/PaymentResult"));
const TermsAndConditions = React.lazy(() => import("./components/views/TermsAndConditions"));
const PrivacyPolicy = React.lazy(() => import("./components/views/PrivacyPolicy"));
const Admin = React.lazy(() => import("./components/views/Admin"));
const ScoresTab = React.lazy(() => import("./components/views/ScoresTab"));

window.store = store;

function App() {
  setupLogRocket();
  setupNewRelic();
  const queryClient = new QueryClient();
  const protectedRoute = (Component) => {
    return (props) => {
      return <ConsentForm content={<Component {...props} />} />;
    };
  };

  /**
   * Route definitions
   * path: the http path. For the in room routes the format is /room/:roomCode/path
   * component: the React component to render
   * protected: whether the route is protected by the consent form
   * availableInRoom: whether the route is available in a room
   * availableOutsideRoom: whether the route is available outside a room
   */
  const routeDefinitions = [
    // Available outside room only
    { path: "/create-room", component: CreateRoom, protected: true, availableInRoom: false, availableOutsideRoom: true }, 
    { path: "/join-room", component: JoinRoom, protected: true, availableInRoom: false, availableOutsideRoom: true },
    // Available in room only
    { path: "/pick-game", component: PickGame, protected: true, availableInRoom: true, availableOutsideRoom: false },
    { path: "/add-friends", component: AddFriends, protected: true, availableInRoom: true, availableOutsideRoom: false },
    { path: "/play", component: Play, protected: true, availableInRoom: true, availableOutsideRoom: false },
    { path: "/how-to-play", component: HowToPlayTab, protected: true, availableInRoom: true, availableOutsideRoom: false },
    { path: "/scores", component: ScoresTab, protected: true, availableInRoom: true, availableOutsideRoom: false },
    // Available in room and outside room
    { path: "/admin", component: Admin, protected: true, availableInRoom: true, availableOutsideRoom: true },
    { path: "/menu", component: MenuTab, protected: true, availableInRoom: true, availableOutsideRoom: true },
    { path: "/payment-result", component: PaymentResult, protected: true, availableInRoom: true, availableOutsideRoom: true },
    // Available unprotected
    { path: "/about", component: About, protected: false, availableInRoom: true, availableOutsideRoom: true },
    { path: "/terms-and-conditions", component: TermsAndConditions, protected: false, availableInRoom: true, availableOutsideRoom: true },  
    { path: "/privacy-policy", component: PrivacyPolicy, protected: false, availableInRoom: true, availableOutsideRoom: true }
  ];

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Provider store={store}>
        <Suspense fallback={<Loading />}>
          <PersistGate loading={null} persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <div>
                  <RouteHandler />
                  <Switch>
                    <Redirect exact from="/" to="/create-room" />
                    {routeDefinitions.flatMap((route, index) => {
                      const renderRoute = route.protected
                        ? protectedRoute(route.component)
                        : (props) => <route.component {...props} />;
                      const routes = [];
                      if (route.availableOutsideRoom) {
                        routes.push(<Route
                          key={index}
                          path={route.path}
                          render={renderRoute}
                        />);
                      }
                      if (route.availableInRoom) {
                        routes.push(<Route 
                          key={index + routeDefinitions.length} 
                          path={`/room/:roomCode${route.path}`} 
                          render={renderRoute} />
                        );
                      }
                      return routes;
                    })}
                  </Switch>
                </div>
              </BrowserRouter>
            </QueryClientProvider>
          </PersistGate>
        </Suspense>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
