import { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect} from 'react-redux';
import { useAsync } from "./utils";
import { connectToRoom, disconnectFromRoom } from "./socketio";
import { extractAccountFromUrl } from "./services/AccountService";

function RouteHandler(props) {
  const { session, history, location } = props;

  const prevPropsRef = useRef(null);
  const prevProps = prevPropsRef.current;
  useEffect(() => {
    prevPropsRef.current = props;
  }, [props]);    

  const getRoomCodeFromLocation = (location) => {
    const roomCodeMatcher = /\/room\/(\w{4})/
    const matches = roomCodeMatcher.exec(location.pathname);
    if (matches && matches.length == 2) {
      return matches[1];
    }
    return null;
  }

  const getRoomCodeFromParams = (location) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get("roomCode");
  }

  extractAccountFromUrl();

  useEffect(() => {
    // We need to support a few redirct scenarios:
    // 1. Redirect to join room if login status changes to false.
    // 2. Redirect to join page if user is logged in to a different room.
    // 3. Redirect from play to join room if the user is not logged in.
    // 4. Redirect from join room to play if the user is already logged in.

    // Redirect to join room if login status changes true to false.
    const roomCode = getRoomCodeFromLocation(location);
    const isLoggingOut = prevProps?.session.isLoggedIn && !session.isLoggedIn;
    if (isLoggingOut && roomCode) {
      console.log("Logging out. Redirecting to join room.");
      history.push(`/join-room?roomCode=${roomCode}`);
      return;
    }

    // Redirect to join room if user is logged in to a different room.
    const isLoggedIn = session.isLoggedIn;
    if (isLoggedIn && roomCode && roomCode !== session.roomCode) {
      console.log("User is logged in to a different room. Redirecting to join room.");
      history.push(`/join-room?roomCode=${roomCode}`);
      return;
    }

    // Redirect from play to join room if the user is not logged in.
    const isLoggedInToRoom = session.isLoggedIn && roomCode && session.roomCode === roomCode;
    if (roomCode && !isLoggedInToRoom) {
      console.log("User is not logged in. Redirecting to join room.");
      history.push(`/join-room?roomCode=${roomCode}`);
      return;
    }

    // Redirect from join room to play if the user is already logged in.
    const roomCodeFromParams = getRoomCodeFromParams(location);
    const isLoggedInToRoomFromParams = session.isLoggedIn &&
      roomCodeFromParams &&
      session.roomCode === roomCodeFromParams;
    if (isLoggedInToRoomFromParams && location.pathname.endsWith("/join-room"))
    {
      console.log("User is already logged in. Redirecting to play page.");
      history.push(`/room/${roomCodeFromParams}/play`);
      return;
    }
  }, [location, session, history, prevProps]);

  // Connect to the room if the room id changes or the log in status changes.
  useAsync(async () => {
    console.log("Connecting to room.");
    if (!props.room?.roomId) {
      console.log("No room to connect to.");
      return;
    }
    if (!session.isLoggedIn) {
      console.log("User is not logged in. Not connecting to room.");
      return;
    }
    
    await connectToRoom(props.room.roomId);
  }, () => true, [props.room?.roomId, session?.isLoggedIn]);

  useEffect(() => {
    console.log("Considering disconnecting from room.");
    if (!props.room?.roomId) {
      console.log("No room to disconnect from.");
      return;
    }

    if (session.isLoggedIn) {
      console.log("User is still logged in. Not disconnecting from room.");
      return;
    }

    disconnectFromRoom(props.room.roomId);
  }, [props.room?.roomId, session?.isLoggedIn]);

  // If the current round id goes from null to a value, redirect to the play page
  useEffect(() => {
    if (props.room?.currentRoundId &&
        !prevProps?.room?.currentRoundId &&
        props.room?.roomCode &&
        session.isLoggedIn &&
        !location.pathname.endsWith("/play")) {
      console.log("New round started. Redirecting to play page.");
      history.push(`/room/${props.room?.roomCode}/play`);
    }
  }, [props.room?.currentRoundId]);

  return null;
}

const mapStateToProps = (state) => {
  return { session: state.session, room: state.room };
};

export default withRouter(connect(mapStateToProps)(RouteHandler));